import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Establecimiento } from 'src/app/models/establecimiento';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {

  miEstablecimiento:Establecimiento=new Establecimiento();
  formCondGenerales: FormGroup;
  formServCorreos: FormGroup;

  showSpinner=false;

  constructor(private miservicio:RestService, private _formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.listarDatosEstablecimiento();

    /*** CONDICIONES GENERALES ***/
    this.formCondGenerales = this._formBuilder.group({
      nombre: ['', 
      // Validators.required
    ],
      descripcion: ['', 
      // Validators.required
    ],
      nif: ['', 
      // Validators.required
    ],          
      email: new FormControl('', 
        // Validators.compose([
        //   Validators.required,
        //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        // ])
      ),
      web: [''],
      tel1: [''],
      tel2: [''],
      direccion: [''],
      cp: [''],
      poblacion: [''],
      provincia: [''],
      pais: [''],
      condgen: [''],
      eslogan: [''],
      polpriv: [''],
      numCuenta: ['']
    })

    /*** SERVIDOR DE CORREOS ***/
    this.formServCorreos = this._formBuilder.group({      
      usuario: [''],
      clave: [''],
      smtp: [''],
      smtpPort: [''],
      sslsmtp: ['']
    })
  }

  listarDatosEstablecimiento(){
    this.miservicio.getEstablecimiento().subscribe(datos=>{
      // console.log(datos)
      this.miEstablecimiento=datos[0];
      // console.log(this.miEstablecimiento)
    })
  }

  guardarCondGenerales(){
    this.showSpinner=true;
    this.miservicio.putEstablecimiento(this.miEstablecimiento).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){

      }else{
        this.openWindow(datos.miRespuesta.strMensaje);
      }
    })
  }

  openWindow(strTexto){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strTexto,
        boton:false
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      // console.log(result)
      

    });
  }

  guardarServCorreos(){
    this.showSpinner=true;
    this.miservicio.putEstablecimiento(this.miEstablecimiento).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner=false;
      if(datos.miRespuesta.booOk){

      }else{
        this.openWindow(datos.miRespuesta.strMensaje);
      }
    })
  }

}
