import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { PedidoVenta } from 'src/app/models/pedido-venta';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { StorageService } from 'src/app/services/storage.service';
import { PedidoOnlineService } from 'src/app/services/pedido-online.service';
import { PedidoAgregarLinea, PedidoAgregar } from 'src/app/models/pedido-agregar';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';

@Component({
  selector: 'app-resumen-compra',
  templateUrl: './resumen-compra.component.html',
  styleUrls: ['./resumen-compra.component.scss']
})
export class ResumenCompraComponent implements OnInit {

  // strCantidad=1;
  miPedido:PedidoVenta=new PedidoVenta();
  strCookie:string;

  miPedidoenCurso:PedidoAgregar=new PedidoAgregar();
  miLineaPedido:PedidoAgregarLinea=new PedidoAgregarLinea();
  booGuardaPedidoSesion=false;
  miPedidoSesion:PedidoVenta=new PedidoVenta();
  showCarrito=false;
  totalArticulos:number=0;
  showSpinner=false;

  constructor(private router:Router,private route: ActivatedRoute, private miServicio:PedidoOnlineService, private dialog: MatDialog,private storageService: StorageService,
    private miStoragePedidoService:StoragePedidoService) {
    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.strCookie=params["coo"];
    });
   }

  ngOnInit() {
    this.showSpinner=true;
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.showCarrito=true;
      if(this.storageService.isAuthenticated()){
        this.verPedido(this.storageService.getCurrentSession().strCookie);
      }else{
        this.verPedido(this.strCookie);
      }
    }else{
      this.showCarrito=false;
      

      if(this.storageService.isAuthenticated()){
        this.router.navigate(['menu/carta'])
      }else{
        let extra: NavigationExtras = {
          queryParams: {
            'coo':this.strCookie
          }
        }

        this.router.navigate(['menu/carta'], extra)
      }
    }

    // this.verPedido();
  }

  verPedido(cookie){

    this.miServicio.getPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.miPedido=datos.miListPedidoVenta[0]
        this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;
        // console.log(this.miPedido)
      }else{
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  tramitarPedido(){

    if(this.storageService.isAuthenticated()){
      this.router.navigate(["/menu/finalizarCompra"]);
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      this.router.navigate(["/inicio/login"], extra);
    }
  }

  add(e){
    // this.strCantidad+=1;
    // console.log(e)
    e.decCantidad+=1;
    this.showSpinner=true;
    this.cambiaCantidadArt(e)
    
  }

  remove(e){
    this.showSpinner=true;
    if(e.decCantidad>1){
      e.decCantidad-=1;
    }
    // console.log(e)
    this.cambiaCantidadArt(e)
  }

  cambiaCantidadArt(e){
    this.showSpinner=true;
    // console.log(e)
    this.miLineaPedido.intId=e.intId;
    this.miLineaPedido.decCantidad=e.decCantidad;
    this.miLineaPedido.intIdArticulo=e.intIdArticulo;
    this.miLineaPedido.strNombreArticulo=e.strNombreArticulo;

    this.miPedidoenCurso.miListPedidoVentaLinea.push(this.miLineaPedido)

    //Compruebo si esta logueado
    if(this.storageService.isAuthenticated()){
      // console.log("IdCliente: "+this.storageService.getCurrentUser().strEntidadId)
      this.miPedidoenCurso.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
      this.strCookie=this.storageService.getCurrentSession().strCookie;
    }else{
      //IdCliente 0
      this.miPedidoenCurso.intIdCliente=0;
    }

    this.compruebaPedido(this.strCookie,this.miPedidoenCurso);
  }

  compruebaPedido(cookie,miPedido){
    //Compruebo si existe un pedido en sesion
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.booGuardaPedidoSesion=false;
      miPedido.intId=this.miStoragePedidoService.getCurrentSessionP().intId;
    }else{
      //Añado el pedido a sesion y lo inserto
      this.booGuardaPedidoSesion=true;
      miPedido.intId=0;
      
    } 
    this.enviaPedido(cookie,miPedido)
  }

  //FALTA COMPROBRAR SI EXISTE LA LINEA

  enviaPedido(cookie,pedido){
    this.miServicio.postPedido(cookie,pedido,true).subscribe(datos=>{

      if(datos.miRespuesta.booOk){
        if(this.booGuardaPedidoSesion){
          this.miPedidoSesion=datos.miListPedidoVenta[0];
          this.miPedidoSesion.miListPedidoVentaLinea=datos.miListPedidoVenta[0].miListPedidoVentaLinea;
          this.guardarSesionPedidoOnline(cookie,datos.miListPedidoVenta[0])
        }else{
          this.showSpinner=false;
          // console.log(datos)

          var index = this.miPedido.miListPedidoVentaLinea.map(x => {
            return x.intId;
          }).indexOf(this.miLineaPedido.intId);
          
          var indexP = datos.miListPedidoVenta[0].miListPedidoVentaLinea.map(x => {
            return x.intId;
          }).indexOf(this.miLineaPedido.intId);

          this.miPedido.miListPedidoVentaLinea[index].decCantidad=datos.miListPedidoVenta[0].miListPedidoVentaLinea[indexP].decCantidad;
          this.miPedido.miListPedidoVentaLinea[index].decImporte=datos.miListPedidoVenta[0].miListPedidoVentaLinea[indexP].decImporte;
          this.miPedido.decImporte=datos.miListPedidoVenta[0].decImporte;

        }
      }
    })
  }

  guardarSesionPedidoOnline(cookie,sesion){
    // console.log(sesion)
       setTimeout(()=>{
        this.recuperarPedido(cookie,this.miStoragePedidoService.getCurrentSessionP().intId)
      }, 500)
  }

  recuperarPedido(cookie,intId){
    this.miServicio.getPedido(cookie,intId).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        this.showSpinner=false;

        this.miPedido=datos.miListPedidoVenta[0]
        // console.log(this.miPedido)
        //foreach

        this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;

        if(datos.miListPedidoVenta[0].miListPedidoVentaLinea.length<=0){
          // this.showCarrito=false;
          // console.log(datos)
          // this.totalArticulos=0;
          // console.log('elimina')
          // console.log(cookie)
          this.eliminarPedido(cookie,datos.miListPedidoVenta[0].intId,0);
          
        }

      }else{
        // console.log(77)
        this.showSpinner=false;
        let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
          width: '600px',
          data:{
            texto:"Se ha producido un error, intentelo mas tarde.",
            boton:false,
            btnError:true
          }
        });
      }
    })
  }

  volverACarta(){
    if(this.storageService.isAuthenticated()){
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.storageService.getCurrentSession().strCookie
        }
      }
      this.router.navigate(['menu/carta'], extra)
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }
      this.router.navigate(['menu/carta'], extra)
    }
  }

  removeLine(o){
    // console.log(o)
    this.showSpinner=true;
    if(this.storageService.isAuthenticated()){
      this.eliminar(this.storageService.getCurrentSession().strCookie,o.intIdPedido,o.intId);
    }else{
      this.eliminar(this.strCookie,o.intIdPedido,o.intId);
    }
  }

  eliminarPedido(cookie,intIdPedido,intIdPedidoLinea){
    // console.log(cookie)
    this.miServicio.delPedido_linea(cookie,intIdPedido,intIdPedidoLinea).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miStoragePedidoService.removeCurrentSessionP();
        this.volverACarta()
      }
    })
  }

  eliminar(cookie,intIdPedido,intIdPedidoLinea){
    this.miServicio.delPedido_linea(cookie,intIdPedido,intIdPedidoLinea).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        // console.log(intIdPedidoLinea)
        // if(intIdPedidoLinea>0){
        //   console.log('tt')
          this.recuperarPedido(cookie,intIdPedido)
        // }else{
        //   console.log('00')
        //   this.miStoragePedidoService.removeCurrentSessionP();
        //   this.volverACarta()
        // }
        
      }
      // else{
      //   let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
      //     width: '600px',
      //     data:{
      //       texto:"Se ha producido un error, intentelo mas tarde.",
      //       boton:false,
      //       btnError:true
      //     }
      //   });
      // }
    })
  }

  volver(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(["/menu/carta"]);
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }
      // console.log(this.strCookie)

      this.router.navigate(["/menu/carta"], extra);
    }
  }

}
