import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { Cliente } from 'src/app/models/cliente';
import { Router } from '@angular/router';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';
import { ChangePassword } from 'src/app/models/change-password';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {

  showCarrito=false;
  totalArticulos=0;
  strCookie:string;
  formNewCliente: FormGroup;
  formChangePassword: FormGroup;
  miCliente:Cliente=new Cliente();
  editarDatos=true;
  guardarDatos=false;
  showSpinner=false;
  submitted=false;
  submitted2=false;
  miChangePassword:ChangePassword=new ChangePassword();
  strMensajeError:string;
  showMsjError=false;

  hide = true;
  hide2 = true;
  hide3 = true;

  constructor(private miservicio:RestService,private miStoragePedidoService:StoragePedidoService, private _formBuilder: FormBuilder, private dialog: MatDialog, private storagaService:StorageService, private router:Router) { }

  ngOnInit() {
    this.showSpinner=true;
    if(this.storagaService.isAuthenticated()){
      this.strCookie=this.storagaService.getCurrentSession().strCookie;

      this.formNewCliente = this._formBuilder.group({
        nombre: ['', Validators.required],
        apellido: [''],
        dni: [''],
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])),
        // email: [''],
        // pass:[''],
        // confirmPass:[''],
        direccion: [''],
        poblacion: [''],
        provincia: [''],
        cp: [''],
        pais: [''],
        tel1: ['', Validators.required],
        tel2: ['']
        // activo: ['']
      });

      this.formChangePassword = this._formBuilder.group({
        oldPass: ['', Validators.required],
        newPass: ['', Validators.required],
        confirmNewPass: ['', Validators.required],
      })
    }

    this.formNewCliente.disable();

    this.verDatosCliente();
    this.verPedido();
  }

  verDatosCliente(){
    this.miservicio.getClienteDetalle(this.strCookie,this.storagaService.getCurrentUser().strEntidadId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        if(datos.miListCliente.length>0){
          this.miCliente=datos.miListCliente[0]
        }
      }
    })
  }

  editarCampos(){
    this.formNewCliente.enable();
    // this.formNewCliente.controls.usuario.disable();
    this.editarDatos=false;
    this.guardarDatos=true;
  }

  guardar(){
    this.submitted=true;
    if(this.formNewCliente.valid){
      this.showSpinner=true;
      this.miservicio.putCliente(this.strCookie,this.miCliente).subscribe(datos=>{
        // console.log(datos)
        if(datos.miRespuesta.booOk){
          this.muestraVentanaEmergente(false,false,"Actualizacion correcta.")
          this.showSpinner=false;
          this.miCliente=datos.miListCliente[0]
          this.formNewCliente.disable();
          this.editarDatos=true;
          this.guardarDatos=false;
        }
      })
    }
  }

  verPedido(){
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.miservicio.getPedido(this.strCookie, this.miStoragePedidoService.getCurrentSessionP().intId).subscribe(datos=>{
        // console.log(datos)
        if(datos.miRespuesta.booOk){
          this.showCarrito=true;
          this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length
        }
      })
    }
  }

  verResumen(){
    this.router.navigate(['/menu/resumen'])
  }

  changePass(){
    this.submitted2=true;
    if(this.formChangePassword.valid){
      if(this.miChangePassword.NewPassword===this.miChangePassword.ConfirmPassword){
        this.showMsjError=false;
        this.showSpinner=true;

        this.miservicio.cambioContrasena(this.strCookie,this.miChangePassword,this.storagaService.getCurrentUser().strEntidadId).subscribe(datos=>{
          // console.log(datos)
          if(datos.miRespuesta.booOk){
            this.showSpinner=false;
            this.showMsjError=false;
            this.muestraVentanaEmergente(false,false,datos.miRespuesta.strMensaje)
          }else{
            this.showSpinner=false;
            this.showMsjError=true;
            this.strMensajeError=datos.miRespuesta.strMensaje;
          }
        })

      }else{
        this.showMsjError=true;
        this.strMensajeError="La contraseña no coindice.";
      }
    }
  }

  muestraVentanaEmergente(booBoton,booBtnError,mensaje){
    let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
      width: '600px',
      data:{
        texto:mensaje,
        boton:booBoton,
        btnError:booBtnError
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      this.showSpinner=false;     
    })
  }

}