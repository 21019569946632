import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { RestService } from 'src/app/services/rest.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PedidoVenta } from 'src/app/models/pedido-venta';
import { PedidoVentaLinea } from 'src/app/models/pedido-venta-linea';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';

@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.component.html',
  styleUrls: ['./detalle-pedido.component.scss']
})
export class DetallePedidoComponent implements OnInit {

  strCookie:string;
  intId:any;
  miPedido:PedidoVenta=new PedidoVenta();
  totalArticulos=0;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  data:PedidoVentaLinea[]=[];
  displayedColumns = ['strImagen1Base64Articulo','strNombreArticulo','decPvp', 'decCantidad', 'decImporte'];
  dataSource;
  showSpinner=false;

  constructor(private miservicio:RestService, private dialog: MatDialog, private storagaService:StorageService, private router:Router, private route: ActivatedRoute,
    private miStoragePedidoService:StoragePedidoService) {
    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.intId=params["id"];
    });
   }

  ngOnInit() {
    this.strCookie=this.storagaService.getCurrentSession().strCookie;
    this.verPedidoDetalle();
    this.verPedido();
    this.showSpinner=true;
  }

  verPedidoDetalle(){
    this.miservicio.getPedidoClienteDetalle(this.strCookie,this.intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.miPedido=datos.miListPedidoVenta[0]
        console.log(this.miPedido)

        this.data=datos.miListPedidoVenta[0].miListPedidoVentaLinea;
        // this.listFiltrada=datos.miListCliente; 
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
      }
    })
  }

  verPedido(){
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.miservicio.getPedido(this.strCookie, this.miStoragePedidoService.getCurrentSessionP().intId).subscribe(datos=>{
        // console.log(datos)
        if(datos.miRespuesta.booOk){
          // this.showCarrito=true;
          this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length
        }
      })
    }
  }
  
  verResumen(){
    this.router.navigate(['/menu/resumen'])
  }

}
