import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog, MatSidenav } from '@angular/material';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { FormControl } from '@angular/forms';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';
import { Establecimiento } from 'src/app/models/establecimiento';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sidenav-cliente',
  templateUrl: './sidenav-cliente.component.html',
  styleUrls: ['./sidenav-cliente.component.scss']
})
export class SidenavClienteComponent implements OnInit {

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  totalArticulos:number=0;
  breakpoint:any;
  notLogIn:boolean;
  LogIn:boolean;
  inicialUser:string;

  //LOGO
  textoApp:string;
  imgLogo:string;
  nombreEst:string;

  // miGupoResult:GrupoResult=new GrupoResult();
  intId:any;
  strCookie:string;

  miEstablecimiento:Establecimiento=new Establecimiento();
 
   onResize(event) {
     this.breakpoint = (event.target.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;
   }
 
   mouseenter() {
     if (!this.isExpanded) {
       this.isShowing = true;
     }
   }
 
   mouseleave() {
     if (!this.isExpanded) {
       this.isShowing = false;
     }
   }

  constructor(private storageService: StorageService, private miStoragePedidoService:StoragePedidoService, private dialog: MatDialog, private router:Router,
    private route: ActivatedRoute,private miLoginService:LoginService
    ) {
    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.strCookie=params["coo"];
    });
  }

  ngOnInit() {

    var event = new Date(this.storageService.getCurrentSession().miToken.expires);
    var jsonDate = event.toJSON();
    var today = new FormControl(new Date()).value.toJSON();
    if(jsonDate>today){
    }else if(jsonDate<today){
      setTimeout(() => {
        this.openWindow();
      }, 100); 
    } 
    
    this.breakpoint = (window.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;

    
    // if(this.storageService.isAuthenticated()){
    //   this.notLogIn=false;
      this.LogIn=true;
      this.inicialUser=this.storageService.getCurrentUser().strUserName.charAt(0).toUpperCase()

      this.nombreEst=this.storageService.getCurrentSession().strEstablecimientoNombre;
      this.textoApp=this.storageService.getCurrentSession().strNombreApp;
      this.imgLogo=this.storageService.getCurrentSession().strLogo;

      this.recuperaDatosGeneralesEstablecimientos();
    //   // this.listarGrupos(this.storageService.getCurrentSession().strCookie);
    // }else{
    //   this.notLogIn=true;
    //   this.LogIn=false;
    //   // this.listarGrupos(this.strCookie);
    // }
  }

  logearse(){
    
  }

  recuperaDatosGeneralesEstablecimientos(){
    this.miLoginService.getDatosGeneralesEst(this.storageService.getCurrentSession().strCookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
      }
    })
  }

  openWindow(){
    let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
      width: '600px',
      data:{
        texto:"Tu sesion ha caducado...",
        boton:false,
        btnError:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.logout();
    });
  }

  public logout(): void{
    // this.storageService.logout();

    //Si existe un pedido en curso lo elimino al hacer el logout
    if(this.miStoragePedidoService.isAuthenticatedP()){
      this.miStoragePedidoService.removeCurrentSessionP();
      this.storageService.logout();
    }else{
      this.storageService.logout();
    }
  }

  verArticulos(){
    this.router.navigate(['/menu/carta'])
  }

  areaCliente(){
    this.router.navigate(['/cliente/area-cliente'])
  }

  verPedidos(){
    this.router.navigate(['/cliente/misPedidos'])
  }
}