import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GrupoResult } from '../models/grupo-result';
import { ArticuloResult } from '../models/articulo-result';
import { PedidoVentaResult } from '../models/pedido-venta-result';
import { EstablecimientoResult } from '../models/EstablecimientoResult';

@Injectable({
  providedIn: 'root'
})
export class PedidoOnlineService {

  constructor(private http: HttpClient ) { }
  
  public url: string = environment.baseUrl;  
  headers = new HttpHeaders().set('Content-Type','application/json');

  // private strToken=this.storageService.getCurrentToken();

  // httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
  // };

  public appDrawer: any;

  getArticuloDetalle(cookie,intId):Observable<ArticuloResult>{
    let dato='strCookie='+cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/articulo/pedidoonline/detalle?'+dato;
  
    return this.http.get<ArticuloResult>(urlCompleta,{headers:this.headers});
  }

  getGrupos(cookie):Observable<GrupoResult>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/grupo/pedidoonline/menu?'+dato;
  
    return this.http.get<GrupoResult>(urlCompleta,{headers:this.headers});
  }
  
  getArticulos(cookie,intIdGrupo, intIdGrupoSub1):Observable<ArticuloResult>{
    let dato='strCookie='+cookie+'&intIdGrupo='+intIdGrupo+'&intIdGrupoSub1='+intIdGrupoSub1;
    let urlCompleta=this.url+'/api/articulo/pedidoonline/grupo?'+dato;
  
    return this.http.get<ArticuloResult>(urlCompleta,{headers:this.headers});
  }

  getArticulosBusqueda(cookie,strParametroLike):Observable<ArticuloResult>{
    let dato='strCookie='+cookie+'&strParametroLike='+strParametroLike;
    let urlCompleta=this.url+'/api/articulo/pedidoonline/busqueda?'+dato;
  
    return this.http.get<ArticuloResult>(urlCompleta,{headers:this.headers});
  }
  
  getArticulosDestacados(cookie):Observable<any>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/articulo/pedidoonline/destacados?'+dato;
  
    return this.http.get<any>(urlCompleta,{headers:this.headers});
  }

  getPedido(cookie,intId):Observable<PedidoVentaResult>{
    let dato='strCookie='+cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/detalle?'+dato;
  
    return this.http.get<PedidoVentaResult>(urlCompleta,{headers:this.headers});
  }
  
  //Añadir pedido
  postPedido(cookie,pedidoVenta,booOcultaImagenLineas):Observable<any>{
    let dato='strCookie='+cookie+'&booOcultaImagenLineas='+booOcultaImagenLineas;
    let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/agregar?'+dato;
  
    var jsonString = JSON.stringify(pedidoVenta);
  
    return this.http.post<any>(urlCompleta,jsonString,{headers:this.headers});
  }

  //Eliminar linea y/o pedido
  delPedido_linea(cookie,intIdPedido,intIdPedidoLinea):Observable<any>{
    let dato='strCookie='+cookie+'&intIdPedido='+intIdPedido+'&intIdPedidoLinea='+intIdPedidoLinea;
    let urlCompleta=this.url+'/api/pedidoventa/pedidoonline/elimina?'+dato;
    
    return this.http.delete<any>(urlCompleta,{headers:this.headers});
  }

  //Politica cookies
  getPoliticaCookies(cookie):Observable<EstablecimientoResult>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/establecimiento/general?'+dato;
  
    return this.http.get<EstablecimientoResult>(urlCompleta,{headers:this.headers});
  }
}
