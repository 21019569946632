import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { PagoFinalizadoComponent } from './components/pago-finalizado/pago-finalizado.component';
import { VentanaEmergentePagosComponent } from './components/ventana-emergente-pagos/ventana-emergente-pagos.component';
import { CartaComponent } from './carta/carta.component';
import { MenuComponent } from './components/menu/menu.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { DetalleArticuloComponent } from './components/detalle-articulo/detalle-articulo.component';
import { ResumenCompraComponent } from './components/resumen-compra/resumen-compra.component';
import { FinalizarCompraComponent } from './components/finalizar-compra/finalizar-compra.component';
import { PoliticaCondicionesEnvioFacturacionComponent } from './components/politica-condiciones-envio-facturacion/politica-condiciones-envio-facturacion.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { APIResolver } from './services/resolve';
import { ClienteComponent } from './components/cliente/cliente.component';
import { DetalleClienteComponent } from './components/detalle-cliente/detalle-cliente.component';
import { AuthGuard } from './guards/authGuard';
import { LayoutClienteComponent } from './components/layout-cliente/layout-cliente.component';
import { SidenavClienteComponent } from './components/sidenav-cliente/sidenav-cliente.component';
import { PedidoFinalizadoComponent } from './components/pedido-finalizado/pedido-finalizado.component';
import { PedidosClienteComponent } from './components/pedidos-cliente/pedidos-cliente.component';
import { DetallePedidoComponent } from './components/detalle-pedido/detalle-pedido.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { VentanaEmergentePagoFinalizadoComponent } from './components/ventana-emergente-pago-finalizado/ventana-emergente-pago-finalizado.component';


const routes: Routes = [
  /** LISTA DE GENERAL DE COMPONENTES **/
  //OCULTO LAS PAGINAS QUE NO SERAN VISIBLES EN EL CATALOGO
  // { path: 'pagos', component: PagosComponent },
  // { path: 'pasarela', component: PasarelaComponent },
  { path: 'ventana-emergente', component: VentanaEmergenteComponent },
  { path: 'ventana-emergente-pagos', component: VentanaEmergentePagosComponent },
  { path: 'ventana-emergente-pago-finalizado', component: VentanaEmergentePagoFinalizadoComponent },
  // { path: 'configuracion', component: ConfiguracionComponent , canActivate: [ AuthAdminGuard ]},
  // { path: 'PagoFinalizado', component: PagoFinalizadoComponent },

  { path: 'menu', component: MenuComponent},
  { path: 'menu-item', component: MenuListItemComponent},

  /** COMPONENTES NUEVOS **/
  { path: 'sidenav', component: SidenavComponent},
  { path: 'sidenav-cliente', component: SidenavClienteComponent},

  // {
  //   path: 'inicio',
  //   component: LoginLayoutComponent,
  //   children: [
  //     {
  //       path: 'login',
  //       component: LoginComponent
  //     }
  //   ]
  // },

  {
    path: 'menu',
    component: LayoutComponent,
    children: [
      { path: 'carta/:intGrupo/:intId', component: CartaComponent},
      { path: 'carta', component: CartaComponent},
      { path: 'detalle/:intArtId', component: DetalleArticuloComponent },
      { path: 'detalle', component: DetalleArticuloComponent },
      //ESTAS PAGINAS NO SERAN VISIBLES
      // { path: 'resumen', component: ResumenCompraComponent },
      // { path: 'finalizarCompra', component: FinalizarCompraComponent },
      // { path: 'condicionesEnvio', component: PoliticaCondicionesEnvioFacturacionComponent,canActivate: [ AuthGuard ] },
      // { path: 'pedidoFinalizado', component: PedidoFinalizadoComponent,canActivate: [ AuthGuard ] },
      // { path: 'pedidoFinalizado/:intId', component: PedidoFinalizadoComponent, canActivate: [ AuthGuard ] },
      { path: 'politica-cookies', component: PoliticaCookiesComponent },
    ]
  },

  { path: 'footer', component: FooterComponent },

  
  //NO SERA VISIBLE NINGUNA DE LAS PAGINAS DE CLIENTE
  // {
  //   path: 'cliente',
  //   component: LayoutClienteComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: 'area-cliente', component: ClienteComponent},
  //     { path: 'detalle-cliente', component: DetalleClienteComponent },
  //     { path: 'misPedidos', component: PedidosClienteComponent },
  //     { path: 'detalle-pedido', component: DetallePedidoComponent },
  //   ]
  // },

  /** PERMISOS PARA COMPONENTES - GUARDS ***/
  /** COMPONENTES POR DEFECTO **/
  { path: '', redirectTo: '/menu/carta', pathMatch: 'full' },
  { path: '**', redirectTo: '/menu/carta', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
