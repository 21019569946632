import { PedidoVentaLinea } from './pedido-venta-linea';

export class PedidoAgregar {

    public intId?:number;
    public intIdCliente?:number;
    // public miListPedidoVentaLinea?: PedidoVentaLinea[] = [];
    public miListPedidoVentaLinea?: PedidoAgregarLinea[] = [];

}

export class PedidoAgregarLinea{

    public intId?:number;
    public intIdArticulo?:number;
    public strNombreArticulo?:string;
    public decCantidad?:number;

}
