import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog } from '@angular/material';
import { PedidoVenta } from 'src/app/models/pedido-venta';
import { VentanaEmergentePagosComponent } from '../ventana-emergente-pagos/ventana-emergente-pagos.component';
import { StorageService } from 'src/app/services/storage.service';
import { Cliente } from 'src/app/models/cliente';
import { environment } from 'src/environments/environment';
import { FinalizaPedido } from 'src/app/models/finaliza-pedido';
import { StoragePedidoService } from 'src/app/services/storage-pedido.service';
import { DetalleClienteComponent } from '../detalle-cliente/detalle-cliente.component';
import { VentanaEmergentePagoFinalizadoComponent } from '../ventana-emergente-pago-finalizado/ventana-emergente-pago-finalizado.component';

@Component({
  selector: 'app-finalizar-compra',
  templateUrl: './finalizar-compra.component.html',
  styleUrls: ['./finalizar-compra.component.scss']
})
export class FinalizarCompraComponent implements OnInit {

  miPedido:PedidoVenta=new PedidoVenta();
  miPedidoSesion:PedidoVenta=new PedidoVenta();
  miCliente:Cliente=new Cliente();
  intFormaPago:number=0;
  strFormaEntrega:string;
  strSubTipo:string;
  checked=false;
  showCarrito=false;
  totalArticulos:number=0;
  strCookie:string;
  miFinalizaPedido:FinalizaPedido=new FinalizaPedido();
  strObservaciones:string="";
  showSpinner=false;
  booTel=false;
  booEmail=false;
  booNombre=false;

  booPagoCorrecto=false;
  booEmailCorrecto=false;

  constructor(private router:Router,private route: ActivatedRoute, private miServicio:RestService, private dialog: MatDialog, private storageService: StorageService,
    private miStoragePedidoService:StoragePedidoService) { }

  ngOnInit() {
    // console.log(this.storageService.getCurrentSessionP())
    this.showSpinner=true;
    //verifico si se ha logueado
    if(this.storageService.isAuthenticated()){
      this.strCookie=this.storageService.getCurrentSession().strCookie;
      //verifico si se ha vinculado el cliente
      if(this.miStoragePedidoService.isAuthenticatedP()){
        this.showCarrito=true;
        if(this.miStoragePedidoService.getCurrentSessionP().intIdCliente!=0){
          // console.log('ya vinculado')
          this.verPedido(this.storageService.getCurrentSession().strCookie,this.miStoragePedidoService.getCurrentSessionP().intId)
        }else{
          this.vincularPedido();
        }
      }else{
        // this.router.navigate(['carta'])
        this.showCarrito=false;
      }
    }else{
      this.router.navigate(['/inicio/login'])
    }

  }

  vincularPedido(){
    // console.log('vamos a vincular')
    this.miServicio.vinculaPedidoCliente(this.storageService.getCurrentSession().strCookie,this.miStoragePedidoService.getCurrentSessionP().intId,this.storageService.getCurrentUser().strEntidadId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.verPedido(this.storageService.getCurrentSession().strCookie,datos.miListPedidoVenta[0].intId);
        this.miStoragePedidoService.removeCurrentSessionP();
        this.guardarSesionPedidoOnline(datos.miListPedidoVenta[0]);
      }else{
        this.muestraVentanaEmergente(false,true,"Se ha producido un error, intentelo mas tarde.")
      }
    })
  }

  guardarSesionPedidoOnline(sesion){
    // console.log(sesion)
    this.miStoragePedidoService.setSessionPagos(sesion);
    // console.log('voy a recuperar el pedido')

      //  setTimeout(()=>{
        
        // console.log(this.storageService.getCurrentSessionP())
      // }, 1000)
  }

  verPedido(cookie,intId){
    // console.log('lo recupero')
    // console.log(cookie)
    this.miServicio.getPedido(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.showSpinner=false;
        this.totalArticulos=datos.miListPedidoVenta[0].miListPedidoVentaLinea.length;
        this.miPedido=datos.miListPedidoVenta[0]
        // console.log(this.miPedido)

        if(datos.miListPedidoVenta[0].miListPedidoVentaLinea.length<=0){
          // this.showCarrito=false
          this.eliminarPedido(cookie,datos.miListPedidoVenta[0].intId,0);
          
        }else{
          this.verCliente(cookie,this.storageService.getCurrentUser().strEntidadId)
        }
      }else{
        this.showSpinner=false;
        this.muestraVentanaEmergente(false,true,"Se ha producido un error, intentelo mas tarde.")
      }
    })
  }

  volverACarta(){
    if(this.storageService.isAuthenticated()){
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.storageService.getCurrentSession().strCookie
        }
      }
      this.router.navigate(['/menu/carta'], extra)
    }else{
      this.router.navigate(['/inicio/login'])
    }
  }

  verCliente(cookie,intId){
    this.miServicio.getClienteDetalle(cookie,intId).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // this.miCliente=datos.miListCliente[0];

        if(datos.miListCliente.length>0){
          this.miCliente=datos.miListCliente[0]
        }

        if(this.miCliente.strDireccion!=''){
          this.miCliente.strDireccion+=',';
          this.miCliente.strPoblacion+=',';
        }
        // console.log(this.miCliente)
      }else{
        this.muestraVentanaEmergente(false,true,"Se ha producido un error, intentelo mas tarde.")
      }
    })
  }

  tarjeta(){
    this.intFormaPago=environment.tarjeta;
    this.strFormaEntrega=environment.recogerTienda;
    this.strSubTipo=environment.pagoTarjeta;
  }

  efectivo(){
    this.intFormaPago=environment.local;
    this.strFormaEntrega=environment.recogerTienda;
    this.strSubTipo=environment.pagoTienda;
  }

  pagar(){
    
    if(this.checked){
      // console.log(this.miCliente)
      if(this.miCliente.strTelefono1==='' && this.miCliente.strTelefono2===''){
        this.muestraVentanaEmergente(false,true,"Antes debe indicar un número de teléfono.")
        this.booTel=false;
      }else{
        this.booTel=true;
      }

      if(this.miCliente.strEmail===''){
        this.muestraVentanaEmergente(false,true,"Antes debe indicar una dirección de correo electrónico.")
        this.booEmail=false;
      }else{
        this.booEmail=true;
      }

      if(this.miCliente.strNombre===''){
        this.muestraVentanaEmergente(false,true,"Antes debe indicar un nombre.")
        this.booNombre=false;
      }else{
        this.booNombre=true;
      }

      if(this.booTel && this.booEmail && this.booNombre){
        this.showSpinner=true;

        // console.log(this.intFormaPago)
        // if(this.intFormaPago !=0){
          // console.log(this.checked)
          this.miFinalizaPedido.intId=this.miStoragePedidoService.getCurrentSessionP().intId;
          this.miFinalizaPedido.strObservaciones=this.strObservaciones;
          this.miFinalizaPedido.intIdCliente=parseInt(this.storageService.getCurrentUser().strEntidadId);
          // this.miFinalizaPedido.intIdFormaPago=this.intFormaPago;
          // this.miFinalizaPedido.strFormaEntrega=this.strFormaEntrega
          // this.miFinalizaPedido.strHoraEntrega="10:00";
          this.miFinalizaPedido.strSubTipo=environment.pagoEnviado;

          this.miServicio.finalizaPedido(this.storageService.getCurrentSession().strCookie,this.miFinalizaPedido).subscribe(datos=>{
            // console.log(datos)
            this.miStoragePedidoService.removeCurrentSessionP();

            //Si el list es >0 --> correcto
            if(datos.miListPedidoVenta.length>0){
              //Pedido finalizado correctamente
              this.booPagoCorrecto=true;
            }else{
              //Error en finalizacion pedido
              this.booPagoCorrecto=false;
            }

            if(datos.miRespuesta.booOk){
              //Email enviado correctamente
              if(this.booPagoCorrecto){
                //Pedido correcto y email correcto
                this.VentanaEmergentePagoFinalizado(false,false,"Pedido realizado correctamente.",false,false);
              }else{
                //Pedido incorrecto y email correcto
                this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al procesar el pedido.",true,false);
              }
            }else{
              //Email incorrecto
              if(this.booPagoCorrecto){
                //Pedido correcto, email incorrecto
                this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al enviar el correo de confirmación.",false,true);
              }else{
                //pedido incorrecto, email incorrecto
                this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al enviar el correo de confirmación.",true,true);
              }
            }


            // if(datos.miRespuesta.booOk){
            //   this.router.navigate(['/menu/pedidoFinalizado']);
            // }else{
            //   this.muestraVentanaEmergente(false,true,"Se ha producido un error.")
            // }

          })
      }
      
    }else{
      this.muestraVentanaEmergente(false,true,"Antes debe aceptar las condiciones de pago.")
    }
  }

  // compruebaPedido(cookie,miPedido){
  //   //Compruebo si existe un pedido en sesion
  //   if(this.storageService.isAuthenticatedP()){
      
  //     miPedido.intId=this.storageService.getCurrentSessionP().intId;
  //     // console.log(this.storageService.getCurrentSessionP())
  //   }else{
  //     //Añado el pedido a sesion y lo inserto
  //     miPedido.intId=0;
      
  //   }     
  // }

  removeLine(o){
    // console.log(o)

    // if(this.storageService.isAuthenticated()){
    //   this.eliminar(this.storageService.getCurrentSession().strCookie,o.intIdPedido,o.intId);
    // }else{
      this.showSpinner=true;
      this.eliminar(o.intIdPedido,o.intId);
    // }
  }

  eliminarPedido(cookie,intIdPedido,intIdPedidoLinea){
    // console.log(cookie)
    this.miServicio.delPedido_linea(cookie,intIdPedido,intIdPedidoLinea).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miStoragePedidoService.removeCurrentSessionP();
        this.volverACarta()
      }
    })
  }

  eliminar(intIdPedido,intIdPedidoLinea){
    this.miServicio.delPedido_linea(this.strCookie,intIdPedido,intIdPedidoLinea).subscribe(datos=>{
      // console.log(datos)

      if(datos.miRespuesta.booOk){
        this.verPedido(this.strCookie,intIdPedido)
      }else{
        this.showSpinner=false;
        this.muestraVentanaEmergente(false,true,"Se ha producido un error, intentelo mas tarde.")
      }
    })
  }

  VentanaEmergentePagoFinalizado(booBoton,booBtnError,mensaje,booPagoError,booEmailError){
    let dialogRef = this.dialog.open(VentanaEmergentePagoFinalizadoComponent, {
      width: '600px',
      data:{
        texto:mensaje,
        boton:booBoton,
        btnError:booBtnError,
        booPagoError:booPagoError,
        booEmailError:booEmailError
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      this.showSpinner=false;
      // console.log(result)
      
      if(result.booEmailError===false){
        //Email enviado correctamente
        if(this.booPagoCorrecto){
          //Pedido correcto y email correcto
          this.router.navigate(['/menu/pedidoFinalizado',1]);
        }else{
          //Pedido incorrecto y email correcto
          // this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al procesar el pedido.",true,false);
          this.router.navigate(['/menu/pedidoFinalizado',2]);
        }
      }else{
        //Email incorrecto
        if(this.booPagoCorrecto){
          //Pedido correcto, email incorrecto
          // this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al enviar el correo de confirmación.",false,true);
          this.router.navigate(['/menu/pedidoFinalizado',3]);
        }else{
          //pedido incorrecto, email incorrecto
          // this.VentanaEmergentePagoFinalizado(false,true,"Se ha producido un error al enviar el correo de confirmación.",true,true);
          this.router.navigate(['/menu/pedidoFinalizado',4]);
        }
      }
    })
  }

  muestraVentanaEmergente(booBoton,booBtnError,mensaje){
    let dialogRef = this.dialog.open(VentanaEmergentePagosComponent, {
      width: '600px',
      data:{
        texto:mensaje,
        boton:booBoton,
        btnError:booBtnError
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      this.showSpinner=false;     
    })
  }

  editarCliente(e){
    let dialogRef = this.dialog.open(DetalleClienteComponent, {
      width:'600px',
      data:e.intId
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        //  console.log(result)
         this.miCliente=result;
         if(this.miCliente.strDireccion!=''){
          this.miCliente.strDireccion+=',';
          this.miCliente.strPoblacion+=',';
        }
      }      
    })
  }

  verCondicionesEnvio(){
    this.router.navigate(['./menu/condicionesEnvio'])
  }


}
